import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import styles from './TermsAndPolicyDialog.styled'
import TermsFtd from './components/TermsDialog'
import PolicyFtd from './components/PolicyDialog'

const useStyles = makeStyles(styles)

const TermsAndPolicyDialog = ({ isOpen, setOpen, handleAccept }) => {
  const classes = useStyles()
  const [checked, setChecked] = useState(false)
  const [isTermsOpen, setIsTermsOpen] = useState(false)
  const [isPolicyOpen, setIsPolicyOpen] = useState(false)

  const handleChange = event => {
    setChecked(event.target.checked)
  }

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return
    setOpen(false)
  }

  return (
    <>
      <Dialog maxWidth={false} open={isOpen} onClose={handleClose}>
        <Grid>
          <DialogContent className={classes.dialog}>
            <Grid className={classes.titleContainer}>
              <Typography className={classes.title}>
                Confirmação de Aceite dos Termos e Condições
              </Typography>
            </Grid>
            <Typography className={classes.description}>
              Você está prestes a acessar a plataforma Farol. Ao continuar e clicar no botão abaixo,
              você confirma que entende e concorda com as regras e condições dos Termos de Uso e da
              Política de Privacidade da Farol e do Instituto Ayrton Senna.
            </Typography>
            <Grid className={classes.checkboxContainer}>
              <div className={classes.container}>
                <Checkbox
                  className={classes.checkbox}
                  checked={checked}
                  onChange={handleChange}
                  name="checkButton"
                  id="checkButton"
                />
                <Typography variant="body2" component="label" htmlFor="checkButton">
                  Eu aceito os&nbsp;
                  <span
                    className={classes.link}
                    role="button"
                    tabIndex={0}
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      setIsTermsOpen(true)
                    }}
                    onKeyDown={event => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        event.preventDefault()
                        event.stopPropagation()
                        setIsTermsOpen(true)
                      }
                    }}
                  >
                    Termos de Uso
                  </span>
                  &nbsp;e a&nbsp;
                  <span
                    className={classes.link}
                    role="button"
                    tabIndex={0}
                    onClick={event => {
                      event.preventDefault()
                      event.stopPropagation()
                      setIsPolicyOpen(true)
                    }}
                    onKeyDown={event => {
                      if (event.key === 'Enter' || event.key === ' ') {
                        event.preventDefault()
                        event.stopPropagation()
                        setIsPolicyOpen(true)
                      }
                    }}
                  >
                    Política de Privacidade
                  </span>
                  &nbsp;da Farol e do Instituto Ayrton Senna.
                </Typography>
              </div>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.buttonContainer}>
            <Button onClick={handleAccept} className={classes.button} disabled={!checked}>
              Sim, concordo e quero ir!
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
      <TermsFtd isOpen={isTermsOpen} setOpen={setIsTermsOpen} />
      <PolicyFtd isOpen={isPolicyOpen} setOpen={setIsPolicyOpen} />
    </>
  )
}

TermsAndPolicyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default TermsAndPolicyDialog
