const reportLibraryTabs = {
  INSTRUMENTOS: {
    type: 'SELECT_INSTRUMENTOS',
    label: 'Instrumentos',
    tab: 0,
    url: '',
  },
  DESEMPENHO_ESCOLAR: {
    type: 'SELECT_DESEMPENHO',
    label: 'Gestão e Desempenho Escolar',
    tab: 1,
    url: '',
  },
}

export default reportLibraryTabs
