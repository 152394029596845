import apiGateway from '../../services/apiGateway'

const verifyFtdFirstLogin = async (setIsTermsAndPolicyOpen, currentUser, isExaminee) => {
  try {
    if (isExaminee !== undefined) {
      const response = await apiGateway.get('/farol-integracao-ftd/user-logged-ftd/exists', {
        params: { id: isExaminee ? currentUser.id : currentUser.user.id, isExaminee },
      })
      const isUserAlreadyExists = response.data
      if (!isUserAlreadyExists) {
        setIsTermsAndPolicyOpen(true)
      }
    }
  } catch (error) {
    console.error('Failed to verify FTD first login')
  }
}

const handleAcceptFtdTermsAndPolicy = async (setIsTermsAndPolicyOpen, currentUser, isExaminee) => {
  if (currentUser && isExaminee !== undefined) {
    try {
      const url = `/farol-integracao-ftd/user-logged-ftd/save/${
        isExaminee ? currentUser.id : currentUser.user.id
      }`
      await apiGateway.post(url, null, { params: { isExaminee } })
      setIsTermsAndPolicyOpen(false)
    } catch (error) {
      console.error('Failed to save FTD user first login')
    }
  }
}

export { verifyFtdFirstLogin, handleAcceptFtdTermsAndPolicy }
