export default function styles(theme) {
  return {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '1px 1px 4px #00000040',
      overflow: 'auto',
      width: '800px',
      height: '650px',
      opacity: 1,
      borderRadius: theme.spacing(0.5),
      gap: '30px',
    },
    container: {
      height: '440px',
      padding: '0px 32px',
      width: '100%',
      overflow: 'auto',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: ' 32px 32px 10px 32px',
    },
    link: {
      color: '#007bff',
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    title: {
      fontSize: '24px',
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '20px',
      color: '#003c78',
      fontWeight: '700',
    },
    text: {
      fontFamily: 'Barlow',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#5f758b',
      textAlign: 'justify',
      padding: '5px 0',
    },
    list: { display: 'block', listStyleType: 'none', padding: 0 },
    listItem: { flexDirection: 'column', alignItems: 'initial' },
  }
}
