const styles = () => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxHeight: '300px',
    maxWidth: '700px',
    height: '100%',
  },
  titleContainer: { display: 'flex', padding: '0 6em' },
  title: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '31.2px',
    textAlign: 'center',
    color: '#003C78',
  },
  description: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20.8px',
    textAlign: 'center',
    color: '#000000',
  },
  checkboxContainer: { display: 'flex', justifyContent: 'center' },
  checkbox: {
    marginRight: 8,
    '& .MuiSvgIcon-root': {
      color: '#003C78',
    },
    '&.Mui-checked': {
      backgroundColor: 'white',
    },
  },
  buttonContainer: {
    justifyContent: 'center',
    padding: '15px',
  },
  container: { display: 'flex', alignItems: 'center' },
  button: {
    maxWidth: '255px',
    maxHeight: '40px',
    background: '#FFCA00',
    borderRadius: '4px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '26px',
    color: '#003C78',
    textTransform: 'uppercase',
    padding: '25px',
    '&:hover': {
      color: '#fff',
    },
  },
  link: {
    color: '#007bff',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
})

export default styles
