import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const reportLibraryTabs = {
  NONE: {
    type: 'SELECT_NONE',
    label: 'Nenhuma',
    tab: 0,
    url: undefined,
  },
  INSTRUMENTOS: {
    type: 'SELECT_INSTRUMENTOS',
    label: 'Instrumentos',
    tab: 0,
    url: '',
  },
  DESEMPENHO_ESCOLAR: {
    type: 'SELECT_DESEMPENHO',
    label: 'Gestão e Desempenho Escolar',
    tab: 1,
    url: '',
  },
}

const initialState = reportLibraryTabs.INSTRUMENTOS

export const ReportTypeContext = createContext({ ...initialState })

export function ReportTypeContextProvider({ children }) {
  const [selectedReportType, setSelectedReportType] = useState(initialState)

  function updateStateTypeReport(value) {
    setSelectedReportType(value)
  }

  return (
    <ReportTypeContext.Provider value={{ selectedReportType, updateStateTypeReport }}>
      {children}
    </ReportTypeContext.Provider>
  )
}

ReportTypeContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default ReportTypeContextProvider
