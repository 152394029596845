/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { isEmpty } from 'lodash'

const ComboBox = ({
  form: { handleBlur },
  value,
  size = 'medium',
  id,
  options,
  label,
  handleChange,
  setFieldValue,
  formatLabel,
  error,
  helperText,
  disabled,
  arrowIcon = false,
  placeholder,
  InputLabelProps,
}) => {
  const [valueOption, setValueOption] = useState([])

  useEffect(() => {
    const getValue = () => {
      if (value) {
        return {
          name: value.name,
          id: value.id,
        }
      }
      return ''
    }
    const initial = getValue()
    setValueOption(initial)
  }, [value])

  let arrOptions = []
  if (Array.isArray(options) && options.length > 0 && !isEmpty(options)) {
    arrOptions = options
      .filter(option => option !== null && typeof option === 'object')
      .map(option => {
        const optionData = {}
        optionData.id = option.id
        optionData.name = option.name
        if (option.uf) optionData.uf = option.uf
        if (option.value) optionData.value = option.value
        if (option.user) optionData.user = option.user
        if (option.profileType) optionData.profileType = option.profileType
        return optionData
      })
  }

  return (
    <Autocomplete
      id={id}
      value={valueOption}
      onChange={(_, newValue) => {
        setFieldValue(id, newValue)
        setValueOption(newValue)
        if (typeof handleChange === 'function') handleChange(newValue)
      }}
      size={size}
      onBlur={handleBlur}
      getOptionLabel={option => (formatLabel ? formatLabel(option) : option.name || '')}
      getOptionSelected={(option, newOption) => option.id === newOption.id}
      options={arrOptions}
      disabled={disabled}
      popupIcon={arrowIcon ? <ExpandMore style={{ color: '#2D3648' }} /> : undefined}
      noOptionsText="Nenhuma opção disponível"
      renderInput={params => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          variant="outlined"
          disabled={disabled}
          style={{
            backgroundColor: disabled ? '#F2F2F2' : '',
          }}
          label={label}
          placeholder={placeholder}
          InputLabelProps={InputLabelProps}
          fullWidth
        />
      )}
    />
  )
}

export default React.memo(ComboBox)
