/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import { Typography, Modal, Grid, Link } from '@material-ui/core'

import styles from './TermsDialog.style'
import Ias from './assets/ias.svg'

const useStyles = makeStyles(styles)

const TermsDialog = ({ isOpen, setOpen }) => {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <>
        <Grid container className={classes.modal}>
          <Grid className={classes.header}>
            <img src={Ias} alt="ias" />
            <Grid>
              <span
                className={classes.link}
                role="button"
                tabIndex={0}
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  handleClose()
                }}
                onKeyDown={event => {
                  if (event.key === 'Enter' || event.key === ' ') {
                    event.preventDefault()
                    event.stopPropagation()
                    handleClose()
                  }
                }}
              >
                Fechar
              </span>
            </Grid>
          </Grid>
          <Grid className={classes.container}>
            <Typography className={classes.text}>
              <strong>
                TERMOS DE USO DA PLATAFORMA SOCIOEMOCIONAIS BNCC - SISTEMA DE AVALIAÇÃO E
                MONITORAMENTO DE COMPETÊNCIAS SOCIOEMOCIONAIS
              </strong>
            </Typography>
            <br />
            <Typography className={classes.text}>
              Há mais de 25 anos procuramos desenvolver o potencial de crianças e jovens no Brasil,
              tendo eleito a educação integral como motriz desse desenvolvimento. É com prazer que
              recebemos você e nos colocamos à disposição para compartilhar o conteúdo e o
              conhecimento que vem sendo desenvolvido pela nossa equipe em alguns momentos de nossa
              história. Caso queira saber mais sobre o Instituto Ayrton Senna, acesse:{' '}
              <Link
                target="_blank"
                rel="noreferrer"
                href="www.institutoayrtonsenna.org.br/pt-br/instituto"
              >
                www.institutoayrtonsenna.org.br/pt-br/instituto
              </Link>
              . Em relação à presente plataforma, o IAS disponibiliza os TERMOS DE USO DA PLATAFORMA
              SOCIOEMOCIONAIS BNCC (“Termo”), que determinam as condições que regulam tanto as ações
              do IAS nesse ambiente, como as suas enquanto Usuário. As diretrizes contidas nestes
              Termos se aplicam unicamente ao uso da Plataforma Socioemocionais BNCC. Outros
              websites que eventualmente possam ser acessados através de links disponibilizados na
              Plataforma Socioemocionais BNCC podem ter Termos de Uso e Políticas de Privacidade e
              Segurança diferentes dos aplicáveis a este ambiente. Portanto, para maiores
              informações, por favor, examine as respectivas políticas e termos destes outros
              websites.
            </Typography>
            <br />
            <br />
            <br />
            <Typography className={classes.text}>
              <strong>
                AO UTILIZAR A PLATAFORMA SOCIOEMOCIONAIS BNCC, VOCÊ AUTOMATICAMENTE CONCORDA COM
                TODAS AS REGRAS DESTES TERMOS DE USO E AS DEMAIS CONDIÇÕES AQUI MENCIONADAS,
                RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E QUAISQUER ATOS POR SI PRATICADOS NA
                PLATAFORMA SOCIOEMOCIONAIS BNCC, BEM COMO PELAS CONSEQUÊNCIAS RELACIONADAS AO SEU
                USO. CASO NÃO CONCORDE COM QUAISQUER DOS TERMOS E CONDIÇÕES ABAIXO ESTABELECIDOS,
                VOCÊ NÃO DEVE UTILIZAR A PLATAFORMA SOCIOEMOCIONAIS BNCC.
              </strong>
            </Typography>
            <br />
            <br />
            <br />
            <Typography className={classes.text}>
              O presente documento está divido em três capítulos: (i) Definições; (ii) Termos de
              Uso; e (iii) Disposições Gerais.
            </Typography>
            <br />
            <br />
            <br />
            <Typography className={classes.text}>A. DEFINIÇÕES</Typography>
            <Typography className={classes.text}>
              <strong>1. Conta Pessoal ou Conta: </strong>
            </Typography>
            <Typography className={classes.text}>
              registro e identificação da pessoa natural para a qual foram definidos login e senha
              de acesso à Plataforma Socioemocionais BNCC;
            </Typography>
            <Typography className={classes.text}>
              <strong>2. Conteúdo: </strong>
            </Typography>
            <Typography className={classes.text}>
              quaisquer materiais, de teor informativo, educacional e/ou científico,
              disponibilizados ao Usuário, pelo IAS, na Plataforma Socioemocionais BNCC, incluindo,
              mas sem se limitar, os Instrumentos Avaliativos de titularidade do IAS e demais
              relatórios, manuais, orientações e guias;{' '}
            </Typography>
            <Typography className={classes.text}>
              <strong>3. Dados Pessoais: </strong>
            </Typography>
            <Typography className={classes.text}>
              são informações relacionadas à pessoa natural identificada ou identificável,
              incluindo, mas sem se limitar, nome, e-mail, endereço IP, geolocalização, dados
              sensíveis etc.;
            </Typography>
            <Typography className={classes.text}>
              <strong>4. Gestor da Rede de Ensino: </strong>
            </Typography>
            <Typography className={classes.text}>
              é toda pessoa natural que atua, perante o IAS, como representante legal da Rede de
              Ensino, competente para tanto, e responsável, para todos os fins de direito, pela
              confirmação da identidade doUsuário e validação do correspondente acesso à Plataforma
              Socioemocionais BNCC;
            </Typography>
            <Typography className={classes.text}>
              <strong>5. IAS: </strong>
            </Typography>
            <Typography className={classes.text}>
              é o Instituto Ayrton Senna, responsável pela administração da Plataforma
              Socioemocionais BNCC e que oferece, por meio dela, sem se limitar, mecanismos para
              aplicação de Instrumentos Avaliativos, assim como a análise e interpretação de seus
              respectivos resultados;
            </Typography>
            <Typography className={classes.text}>
              <strong>6. Instrumentos Avaliativos: </strong>
            </Typography>
            <Typography className={classes.text}>
              ferramentas e/ou sistemas de avaliação e monitoramento de competências
              socioemocionais, de titularidade do IAS, e disponibilizados na Plataforma
              Socioemocionais BNCC;
            </Typography>
            <Typography className={classes.text}>
              <strong>7. Plataforma Socioemocionais BNCC ou Plataforma: </strong>
            </Typography>
            <Typography className={classes.text}>
              é a plataforma digital, uma interface online, administrada pelo IAS, cujo endereço
              principal é{' '}
              <Link target="_blank" rel="noreferrer" href="socioemocionaisbncc.org.br">
                socioemocionaisbncc.org.br
              </Link>
              , e por meio da qual são oferecidos ao Usuário, entre outros, Conteúdos e acessos aos
              Instrumentos Avaliativos, de titularidade do IAS;
            </Typography>
            <Typography className={classes.text}>
              <strong>8. Política de Privacidade: </strong>
            </Typography>
            <Typography className={classes.text}>
              documento que tem por objetivo esclarecer quais os termos e condições que guiam as
              atividades de tratamento de dados pelo IAS, aplicável à presente Plataforma, bem como
              às suas ações e atividades como Usuário do referido ambiente, disponível também para
              acesso no site institucional do IAS, no endereço{' '}
              <Link
                target="_blank"
                rel="noreferrer"
                href="institutoayrtonsenna.org.br/content/dam/institutoayrtonsenna/documentos/instituto-ayrton-senna-ooliticas-de-privaciadade-e-seguranca.pdf"
              >
                institutoayrtonsenna.org.br/content/dam/institutoayrtonsenna/documentos/instituto-ayrton-senna-ooliticas-de-privaciadade-e-seguranca.pdf
              </Link>{' '}
              e que, em conjunto com o presente Termo, define todas as regras e condições aplicáveis
              ao uso da Plataforma Socioemocionais BNCC;
            </Typography>
            <Typography className={classes.text}>
              <strong>9. Rede de Ensino: </strong>
            </Typography>
            <Typography className={classes.text}>
              é a entidade, pública ou privada, responsável por confirmar e validar, por meio do
              respectivo Gestor da Rede de Ensino, perante o IAS, a identidade do Usuário para fins
              de liberação de acesso ao uso da Plataforma Socioemocionais BNCC;
            </Typography>
            <Typography className={classes.text}>
              <strong>10. Servidores do IAS: </strong>
            </Typography>
            <Typography className={classes.text}>
              programas de computador ou aparelhos de computador que fornecem à Plataforma
              Socioemocionais BNCC serviços de web, e-mail, banco de dados, proxy, FTP, sistema
              operacional, entre outros;
            </Typography>
            <Typography className={classes.text}>
              <strong>11. Usuário: </strong>
            </Typography>
            <Typography className={classes.text}>
              é toda pessoa natural que tem acesso validado pela respectiva Rede de Ensino a qual
              está vinculada e que utiliza a Plataforma Socioemocionais BNCC, mediante o cadastro da
              Conta, ou interage com as funcionalidades oferecidas por esse ambiente, sendo certo
              que o Usuário deverá ter capacidade legal para aceitar e consentir com os termos deste
              documento, e, caso não tenha, as autorizações necessárias serão dadas pelos
              respectivos responsáveis legais.
            </Typography>
            <Typography className={classes.text}>
              <strong>12. Titular da Conta ou Titular: </strong>
            </Typography>
            <Typography className={classes.text}>
              pessoa natural, vinculada à Rede de Ensino, incluindo os Usuários e o Gestor da Rede
              de Ensino, à qual foi criada ou atribuídauma Conta para acesso e utilização da
              Plataforma.
            </Typography>
            <br />
            <br />
            <br />
            <Typography className={classes.text}>B. TERMOS DE USO </Typography>
            <Typography className={classes.text}>
              Neste capítulo, o IAS apresenta as condições e regras de utilização e navegação da
              Plataforma, conforme os seguintes cenários: (i) condições de uso; (ii) cadastro e
              cancelamento da Conta Pessoal; (iii) responsabilidades do Usuário; (iv) propriedade
              intelectual; (v) segurança; (vi) garantias e limitações de responsabilidade; (vii)
              manutenção e suporte.
            </Typography>
            <br />
            <br />
            <br />
            <Typography className={classes.text}>
              <strong>1. CONDIÇÕES DE USO: COMO UTILIZAR ESTA PLATAFORMA</strong>
            </Typography>
            <Typography className={classes.text}>
              <strong>1.1. </strong>
            </Typography>
            <Typography className={classes.text}>
              A Plataforma funciona mediante conexão à internet e o acesso e uso são restritos
              àqueles que tiveram a identidade confirmada pela Rede de Ensino a qual está atualmente
              integrado. Os Usuários da Plataforma apenas poderão acessá-la após a validação do seu
              vínculo com a Rede de Ensino feita pelo Gestor de Rede de Ensino. O primeiro acesso à
              Plataforma será realizado pelos Usuários mediante link de acesso que serão enviados
              aos seus respectivos e-mails já validados pelo Gestor de Rede de Ensino. A partir
              disso, os Usuários criarão login e senha individuais da Conta para acesso à
              Plataforma. Ao utilizar a Plataforma, o Usuário ou Gestor da Rede de Ensino declara
              ter todos os poderes e autorização para acessá-la de acordo com as regras aqui
              descritas.
            </Typography>
            <Typography className={classes.text}>
              <strong>1.2. </strong>
            </Typography>
            <Typography className={classes.text}>
              Para acessar a Plataforma, além da aceitação destes Termos de Uso, o Usuário também
              deverá ler e cientificar-se da Política de Privacidade do IAS, aplicável a esta
              Plataforma, disponível aqui. É dever do Usuário estar atento às regras destes Termos
              de Uso e da Política de Privacidade do IAS.
            </Typography>
            <Typography className={classes.text}>
              <strong>2. CADASTRO: COMO SE CADASTRAR NESTA PLATAFORMA</strong>
            </Typography>
            <Typography className={classes.text}>
              <strong>2.1. Gestor da Rede de Ensino. </strong>
            </Typography>
            <Typography className={classes.text}>
              Para realizar o cadastro, o Gestor da Rede de Ensino deverá fornecer voluntariamente
              informações sobre si, por meio do preenchimento de dados nos campos solicitados pelo
              IAS na área de cadastro da Plataforma, como, por exemplo, nome completo, telefone,
              CPF, e-mail, identidade da Rede de Ensino a qual está vinculado, dentre outras
              informações necessárias. A senha criada pelo Gestor da Rede de Ensino deverá cumprir
              com as regras informadas na Plataforma no momento de sua criação.
            </Typography>
            <Typography className={classes.text}>
              <strong>2.2. Usuários. </strong>
            </Typography>
            <Typography className={classes.text}>
              Os Usuários, incluindo professores da Rede de Ensino, terão o seu acesso à Plataforma
              validado e fornecido pelo respectivo Gestor da Rede de Ensino, para posterior criação
              da sua Conta, por meio de dados como CPF, nome completo, e-mail e função. Como
              condição de liberação de acesso e utilização da Plataforma, o Usuário reconhece que
              está vinculado à Rede de Ensino cadastrada.
            </Typography>
            <Typography className={classes.text}>
              <strong>2.3. Direitos. </strong>
            </Typography>
            <Typography className={classes.text}>
              Em atenção ao cumprimento destes Termos de Uso, o IAS outorga ao Usuário o direito de
              uso e a assinatura pessoais, limitados, sem limitação de território, não exclusivos,
              intransferíveis, revogáveis e não sublicenciáveis para utilização da Plataforma.
            </Typography>
            <Typography className={classes.text}>
              <strong>2.4. </strong>
            </Typography>
            <Typography className={classes.text}>
              O IAS esclarece que é de responsabilidade do Usuário e do Gestor da Rede de Ensino
              manter as informações corretas, completas e atualizadas em sua Conta. A atualização
              dos dados cadastrais pode ser feita voluntariamente dentro da própria Plataforma. O
              IAS não é responsável pelas informações prestadas, mas reserva-se o direito de
              verificar, a qualquer momento, a veracidade de tais informações e solicitar, a seu
              critério exclusivo, a documentação de suporte que julgar necessária para a devida
              comprovação das informações prestadas. É proibido assumir intencionalmente a
              identidade de outra pessoa.
            </Typography>
            <Typography className={classes.text}>
              <strong>2.5. </strong>
            </Typography>
            <Typography className={classes.text}>
              Após a realização do cadastro, o IAS enviará um e-mail de confirmação, contendo um
              link que deverá ser acessado para a validação do cadastro realizado. Caso não haja o
              recebimento deste e-mail, o reenvio poderá ser solicitado pela Plataforma a partir de
              abertura de chamado no canal “Precisa de Ajuda”. A não confirmação do cadastro implica
              a impossibilidade de utilizar a Plataforma.
            </Typography>
            <Typography className={classes.text}>
              <strong>2.6. </strong>
            </Typography>
            <Typography className={classes.text}>
              A partir do cadastro, o Gestor da Rede de Ensino ou Usuário será titular de uma Conta
              Pessoal que somente poderá ser acessada por ele próprio, mediante o login e a senha
              criados. Caso o IAS detecte alguma Conta feita a partir de Dados Pessoais falsos, o
              cadastro na Plataforma será automaticamente cancelado e o acesso à Plataforma será
              bloqueado.
            </Typography>
            <Typography className={classes.text}>
              <strong>2.7. </strong>
            </Typography>
            <Typography className={classes.text}>
              O Titular da Conta concorda que detém conhecimentos prévios e suficientes de hardware
              e software para usufruir da Plataforma e de todas as suas atividades, operando a mesma
              sob a sua inteira e exclusiva responsabilidade por meio da sua Conta, de forma que se
              compromete a não compartilhar as informações desta Plataforma, inclusive as que se
              refiram à Conta, com terceiros, mantendo sempre a segurança e o sigilo do login e da
              senha da sua Conta. Caso o Titular tome conhecimento de que os dados da sua Conta não
              estão mais seguros ou sob sigiloso, e/ou que esta tenha sido comprometida ou acessada
              sem autorização, o IAS solicita ao Titular que imediatamente nos informe por meio do
              endereço atendimento.senna@ias.org.br, para que possam ser tomadas medidas de
              segurança possíveis e necessárias.
            </Typography>
            <Typography className={classes.text}>
              <strong>2.7.1. </strong>
            </Typography>
            <Typography className={classes.text}>
              Caso o Usuário ou Gestor da Rede de Ensino se esqueça da senha e precise recuperá-la,
              poderá solicitar a criação de uma nova senha por meio da Plataforma a partir de
              abertura de chamado no canal “Precisa de Ajuda”. Caso a solicitação seja realizada, o
              IAS enviará ao Usuário ou Gestor da Rede de Ensino um e-mail para o endereço
              eletrônico indicado no cadastro, que conterá um link, o qual deverá ser acessado para
              que uma nova senha seja criada. A nova senha deverá respeitar as regras de segurança
              do IAS.
            </Typography>
            <Typography className={classes.text}>
              <strong>2.8. </strong>
            </Typography>
            <Typography className={classes.text}>
              A fim de garantir o funcionamento da Plataforma, o IAS pode enviar notificações
              administrativas, incluindo, entre outras, informações sobre a Conta, confirmação de
              registro em atividades, informações sobre o progresso ou outras que sejam necessárias
              ao acesso e/ou ao uso das funcionalidades da Plataforma. O IAS não se responsabilizará
              por qualquer fato decorrente da impossibilidade de o Titular da Conta receber
              notificações enviadas pelo IAS a um endereço incorreto, incompleto ou desatualizado.
            </Typography>
            <Typography className={classes.text}>
              <strong>3. CADASTRO: CONDIÇÕES PARA O CANCELAMENTO DO CADASTRO NA PLATAFORMA</strong>
            </Typography>
            <Typography className={classes.text}>
              <strong>3.1. Cancelamento pelo Usuário ou Gestor da Rede de Ensino. </strong>
            </Typography>
            <Typography className={classes.text}>
              O Titular da Conta tem o direito de solicitar o cancelamento do cadastro na
              Plataforma, a qualquer momento, mediante a abertura de chamado no canal “Precisa de
              Ajuda” da Plataforma. É dever do Gestor da Rede de Ensino solicitar o cancelamento da
              Conta cujo titular não tenha mais vínculo com a respectiva Rede de Ensino.
            </Typography>
            <Typography className={classes.text}>
              <strong>3.2. Cancelamento pelo IAS. </strong>
            </Typography>
            <Typography className={classes.text}>
              O IAS reserva-se o direito de suspender ou cancelar, a qualquer momento, sem prévia
              notificação, o cadastro de qualquer Usuário ou Gestor da Rede de Ensino, caso
              identifique a violação de direitos de propriedade intelectual, ocorrência de desvio de
              finalidade na utilização da Plataforma, fraude, obtenção de benefício ou vantagem de
              forma ilícita ou o não cumprimento de quaisquer das condições previstas nestes Termos
              de Uso ou na legislação aplicável. Nesses casos, nenhuma indenização será devida à
              Rede de Ensino, Usuário, Gestor da Rede de Ensino ou a terceiros pelos danos causados.
              O Usuário ou Gestor da Rede de Ensino está ciente e de acordo que o IAS poderá
              promover todas as medidas necessárias para perseguir e resguardar seus direitos,
              incluindo medidas judiciais.
            </Typography>
            <Typography className={classes.text}>
              <strong>3.2.1. </strong>
            </Typography>
            <Typography className={classes.text}>
              Uma vez que o acesso à Plataforma decorre do relacionamento entre a Rede de Ensino e o
              IAS, o IAS também poderá suspender ou cancelar o cadastro de qualquer Usuário ou
              Gestor da Rede de Ensino, a qualquer momento e sem aviso prévio, nos casos aplicáveis
              em que forem celebrados também Contratos de Parceria, caso alguma condição prevista no
              referido documento não seja cumprida pela Rede de Ensino que celebrou tal contrato com
              o IAS ou caso tal contrato seja rescindido, por qualquer motivo. Nenhuma indenização
              será devida à Rede de Ensino ou a terceiros pelos danos causados nesse sentido.
            </Typography>
            <Typography className={classes.text}>
              <strong>3.3. Consequências do cancelamento. </strong>
            </Typography>
            <Typography className={classes.text}>
              Sem prejuízo das demais consequências previstas na legislação aplicável e nestes
              Termos de Uso, o titular da Conta está ciente de que o cancelamento do cadastro
              implica, além do encerramento da Conta, o bloqueio do acesso à Plataforma, de modo que
              não será mais possível utilizá-la, bem como a indisponibilização de todas as
              informações relacionadas à Conta, não sendo possível recuperar o acesso à Plataforma e
              a qualquer dado a ela relacionada. Caso o cadastro seja apenas suspenso, o acesso à
              Plataforma e à Conta estará suspenso por 30 (trinta) dias ou até que o conflito que
              tenha dado causa à suspensão seja resolvido, o que ocorrer primeiro. Decorrido esse
              prazo sem que a questão que ensejou a suspensão seja resolvida, o cadastro e Conta
              serão definitivamente cancelados e excluídos, implicando todas as consequências do
              cancelamento aqui previstas. A impossibilidade de acesso à Plataforma ou a dados
              relacionados à Conta em hipótese alguma dará ao titular ou à Rede de Ensino qualquer
              direito à indenização.
            </Typography>
            <Typography className={classes.text}>
              <strong>4. RESPONSABILIDADES DO USUÁRIO E DO IAS</strong>
            </Typography>
            <Typography className={classes.text}>
              <strong>4.1. Obrigações do Titular da Conta. </strong>
            </Typography>
            <Typography className={classes.text}>
              Considerando a finalidade da Plataforma e a importância da manutenção de um ambiente
              seguro e harmonioso para todos, o Titular da Conta, desde já, concorda em: (i)
              utilizar a Plataforma única e exclusivamente no limite destes Termos e tão somente
              para fins pedagógicos e/ou de política educacional; (ii) acessar a Plataforma em
              locais adequados, respeitando o limite de tempo de exposição à tela do dispositivo
              utilizado (computadores, tablets, smartphones, entre outros), bem como a distância
              mínima entre a tela e o Titular da Conta; (iii) responsabilizar-se pela veracidade e
              exatidão das informações fornecidas por si e inseridas e/ou compartilhadas na
              Plataforma; (iv) assegurar o sigilo e zelar pela privacidade de toda e qualquer
              informação, dado, resultado ou documento que eventualmente tenha acesso em razão da
              aplicação dos Instrumentos Avaliativos e da utilização das demais funcionalidades e/ou
              Conteúdo da Plataforma(v) responsabilizar-se pelo cumprimento de toda e qualquer
              legislação aplicável à utilização da Plataforma Socioemocionais BNCC; e (vi) verificar
              periodicamente as atualizações dos presentes Termos.
            </Typography>
            <Typography className={classes.text}>
              <strong>4.2. Práticas proibidas. </strong>
            </Typography>
            <Typography className={classes.text}>
              O Titular da Conta fica ciente e concorda que, na utilização da Plataforma, é
              terminantemente proibido: (i) postar, inserir, incluir, carregar, transmitir ou, de
              outro modo, disponibilizar por meio da Plataforma quaisquer Dados Pessoais, Conteúdos,
              comunicações ou demais informações que tenham sido obtidas de forma ilegítima, estejam
              desatualizadas ou sejam obscenas, falsas, indecentes, difamatórias ou caluniem,
              abusem, constranjam, discriminem ou ameacem outros; (ii) disseminar ou instalar vírus,
              trojans, worms, bombas-relógio, robôs de cancelamento, outros dispositivos maliciosos
              ou componentes nocivos que possam danificar, interferir negativamente, interceptar
              clandestinamente ou desapropriar qualquer sistema, informação ou Dados Pessoais; (iii)
              coletar, tratar, divulgar ou disponibilizar informações não autorizadas, de acordo com
              qualquer legislação ou relações contratuais ou fiduciárias (tais como informações
              privilegiadas, exclusivas e confidenciais aprendidas ou divulgadas como parte de
              vínculos empregatícios ou sob contratos de sigilo); (iv) violar os direitos autorais,
              patentes, marcas registradas, segredos comerciais, direito de publicidade ou quaisquer
              outros direitos de propriedade intelectual ou direitos de propriedade de terceiros;
              (v) violar os direitos de outros Titulares de Conta da Plataforma ou quaisquer
              legislações locais, estaduais, nacionais ou internacionais aplicáveis ou que de outro
              modo defendam ou estimulem atividade ilegal; (vi) utilizar meios automatizados ou
              eletrônicos para acessar a Plataforma (inclusive, robots, spiders, scripts, entre
              outros); (vii) fraudar, interferir ou interromper quaisquer atividades ou serviços da
              Plataforma, Servidores, navegadores ou redes conectadas à Plataforma ou descumprir
              quaisquer exigências, procedimentos, políticas ou regulamentos relacionados; e (viii)
              exceto quando prévia e expressamente autorizado pelo respectivo titular ou
              responsável, divulgar, revelar, publicar, direta ou indiretamente, por qualquer meio
              de comunicação, dados, imagens e/ou informações contendo Dados Pessoais de terceiros.
            </Typography>

            <Typography className={classes.text}>
              <strong>5. PROPRIEDADE INTELECTUAL: COMO É REGULADO O CONTEÚDO NA PLATAFORMA</strong>
            </Typography>
            <Typography className={classes.text}>
              <strong>5.1. </strong>
            </Typography>
            <Typography className={classes.text}>
              O direito à exploração e/ou utilização da imagem e do nome de Ayrton Senna da Silva
              foi licenciado pela Ayrton Senna Empreendimentos Ltda. (“ASE”) para o IAS, para que o
              mesmo pudesse obter, através de seu sublicenciamento, parte dos recursos necessários
              para atingir os objetivos para os quais foi criado. Assim, a reprodução não
              autorizada, sob qualquer forma, meio ou recurso, bem como a exploração e/ou utilização
              indevida da imagem e/ou do nome de Ayrton Senna da Silva constitui violação dos
              direitos de exclusividade de uso garantidos à ASE, pelo piloto Ayrton Senna da Silva,
              passível de medidas no âmbito penal e civil.
            </Typography>
            <Typography className={classes.text}>
              <strong>5.2. Conteúdo. </strong>
            </Typography>
            <Typography className={classes.text}>
              Todas as funcionalidades e os direitos de propriedade intelectual da Plataforma,
              incluindo textos, fotografias, imagens, logomarcas, marcas, layouts, software,
              códigos, bases de dados, gráficos, artigos, vídeos, obras audiovisuais e som presentes
              nos Instrumentos Avaliativos ou em qualquer outro Conteúdo produzido direta ou
              indiretamente e disponibilizado na Plataforma são licenciados e/ou são de titularidade
              exclusiva do próprio IAS.
            </Typography>
            <Typography className={classes.text}>
              <strong>5.3. </strong>
            </Typography>
            <Typography className={classes.text}>
              O Conteúdo do IAS é protegido pela legislação de direitos de propriedade intelectual,
              incluindo direitos autorais, direitos de propriedade industrial, dentre outros
              direitos. Sem o consentimento prévio e expresso do IAS, é expressamente vedada a
              reprodução, cópia, distribuição, tradução, venda, licenciamento, exploração, upload,
              execução, alteração, publicação, republicação, transmissão, modificação, adaptação
              total ou parcial, utilização de scrapping, engenharia reversa e/ou tentativa de
              reversão, sob qualquer meio ou forma, do Conteúdo que compõe a Plataforma, sendo certo
              que em hipótese alguma o Titular da Conta adquirirá quaisquer direitos sobre os
              mesmos. O Titular da Conta assume toda e qualquer responsabilidade de caráter civil
              e/ou penal pela utilização indevida do Conteúdo disponibilizado.
            </Typography>
            <Typography className={classes.text}>
              <strong>5.4. </strong>
            </Typography>
            <Typography className={classes.text}>
              Nenhuma disposição destes Termos de Uso deve ser entendida como restrição ou renúncia
              de quaisquer direitos do IAS sobre a Plataforma ou o Conteúdo disponibilizado,
              tampouco cessão aos Titulares de Conta ou à Rede de Ensino dos direitos de propriedade
              intelectual do IAS.
            </Typography>
            <Typography className={classes.text}>
              <strong>5.5. Melhorias. </strong>
            </Typography>
            <Typography className={classes.text}>
              Todo e qualquer desenvolvimento, arranjo, melhoria, atualização ou nova versão
              realizados pelo IAS na Plataforma, nas suas funcionalidades e/ou no Conteúdo, ainda
              que por sugestão ou solicitação do Titular da Conta, serão considerados de
              titularidade do IAS, podendo o IAS empregá-los livremente e, sem limitação,
              adaptá-los, aperfeiçoá-los, transformá-los, reproduzi-los, distribui-los,
              comercializá-los, levá-los a registro, cedê-los e licenciá-los, a qualquer título e a
              seu exclusivo critério.
            </Typography>
            <Typography className={classes.text}>
              <strong>5.6. Feedback. </strong>
            </Typography>
            <Typography className={classes.text}>
              O Titular da Conta reconhece que o IAS é livre para usar, aplicar, modificar,
              publicar, reproduzir e comercializar quaisquer comentários, informações, ideias,
              conceitos, opiniões, técnicas e/ou quaisquer outros materiais contidos em qualquer
              comunicação que o Titular da Conta envie ao IAS, seja por meio da Plataforma ou por
              outros canais de comunicação, incluindo, sem limitação, qualquer sugestão, solicitação
              de melhorias, recomendações oferecidas pelo Titular da Conta ao IAS, sejam ou não
              relacionadas ao Conteúdo e/ou à Plataforma (“Feedback”). O Titular da Conta, neste
              ato, cede ao IAS todos os direitos patrimoniais de propriedade intelectual
              relacionados aos Feedbacks, de forma gratuita, irrevogável, irretratável, total,
              perpétua e global, sem que seja devido ao Titular da Conta qualquer remuneração,
              reconhecimento, pagamento ou indenização. O Titular da Conta declara e garante que,
              anteriormente à presente cessão, era o titular de todos os direitos de propriedade
              intelectual sobre os Feedbacks e que o oferecimento dos Feedbacks ao IAS, incluindo a
              presente cessão, não viola quaisquer direitos de terceiros.
            </Typography>
            <Typography className={classes.text}>
              <strong>
                6. SEGURANÇA: O QUE É FEITO PELO IAS PARA GARANTIR A SEGURANÇA DA PLATAFORMA E COMO
                O USUÁRIO DEVE CONTRIBUIR
              </strong>
            </Typography>
            <Typography className={classes.text}>
              <strong>6.1. </strong>
            </Typography>
            <Typography className={classes.text}>
              O IAS esclarece ao Titular da Conta que toma medidas técnicas e organizacionais
              adequadas para garantir a segurança da Plataforma, mas não oferece aos Titulares de
              Conta dispositivos de hardware, serviços de conexão à internet ou qualquer outro
              serviço técnico necessário para o uso da Plataforma, isentando-se de qualquer
              responsabilidade nesse sentido. Portanto, o IAS reforça que é de responsabilidade do
              Titular da Conta: (i) a segurança do ambiente de seus dispositivos de acesso
              (computador, celular, tablet, entre outros) à presente Plataforma, valendo-se de
              ferramentas específicas para tanto, tais como antivírus, firewall, entre outras, de
              modo a contribuir para a prevenção de riscos eletrônicos; (ii) valer-se de sistemas
              operacionais e navegadores atualizados e eficientes para a plena utilização da
              Plataforma, de acordo com seus requisitos técnicos; e (iii) equipar-se e
              responsabilizar-se pelos computadores, dispositivos móveis e dispositivos de hardware
              necessários para o acesso à Plataforma, bem como pelo acesso desses computadores e
              dispositivos à Internet.
            </Typography>
            <Typography className={classes.text}>
              <strong>7. GARANTIAS E LIMITAÇÕES DE RESPONSABILIDADE</strong>
            </Typography>
            <Typography className={classes.text}>
              <strong>7.1. </strong>
            </Typography>
            <Typography className={classes.text}>
              O Titular da Conta reconhece e concorda expressamente que a Plataforma é oferecida
              “tal como se apresenta” e “conforme a disponibilidade”. O IAS recusa todas as
              declarações e garantias, expressas, implícitas ou legais, não expressamente contidas
              neste Termo, inclusive as garantias implícitas de adequação a uma finalidade
              específica e de não infringência.
            </Typography>
            <Typography className={classes.text}>
              <strong>7.2. Isenção de Responsabilidade. </strong>
            </Typography>
            <Typography className={classes.text}>
              O IAS e seus conselheiros, diretores, representantes, colaboradores, cessionários ou
              parceiros não serão, em hipótese alguma, responsabilizados por danos, diretos ou
              indiretos, que resultem ou que tenham relação com o acesso ou uso do Titular da Conta
              da Plataforma ou com a sua incapacidade de acesso ou utilização.
            </Typography>
            <Typography className={classes.text}>
              <strong>7.3. Serviços de Terceiros. </strong>
            </Typography>
            <Typography className={classes.text}>
              O IAS utiliza serviços de terceiros para manter o funcionamento da Plataforma (por
              exemplo, serviço de hospedagem), podendo, portanto, eventualmente ocorrer falhas em
              tais serviços. O IAS não será responsável por quaisquer perdas, danos (diretos ou
              indiretos) e lucros cessantes decorrentes de eventual falha dos serviços destes
              terceiros, mas, na medida do possível, manterá o Titular da Conta informado sobre
              prazos e providências tomadas para sanar referida falha.
            </Typography>
            <Typography className={classes.text}>
              <strong>7.4. Decisões. </strong>
            </Typography>
            <Typography className={classes.text}>
              Em hipótese alguma o IAS será responsabilizado por qualquer consequência ou dano
              proveniente de decisões que sejam tomadas pelo Titular da Conta com base nos
              Instrumentos Avaliativos e demais Conteúdos gerados e disponibilizados na Plataforma,
              e desde já está isento de toda e qualquer responsabilidade pelas perdas e danos,
              diretos e indiretos, causados ao Titular da Conta ou a terceiros, em razão de tais
              decisões.
            </Typography>
            <Typography className={classes.text}>
              <strong>7.5. Resultados. </strong>
            </Typography>
            <Typography className={classes.text}>
              O Titular da Conta declara e concorda que o Conteúdo e/ou funcionalidades oferecidas
              pela Plataforma são típica obrigação de meio, de forma que o IAS não garante qualquer
              rendimento econômico, economia ou resultado comercial ou de outra natureza em relação
              ao uso do Conteúdo e/ou das funcionalidades oferecidas pela Plataforma ao Titular da
              Conta, à Rede de Ensino ou a quaisquer terceiros, eximindo-se de toda responsabilidade
              no caso de danos e prejuízos de qualquer natureza causados nesse sentido ao Titular da
              Conta, à Rede de Ensino ou a terceiros.
            </Typography>
            <Typography className={classes.text}>
              <strong>7.6. Penalidades. </strong>
            </Typography>
            <Typography className={classes.text}>
              O Titular da Conta é responsável por toda e qualquer violação destes Termos de Uso e
              da lei aplicável, seja por ação ou omissão. No caso de infração cometida em razão do
              descumprimento destes Termos de Uso, violação de qualquer lei aplicável ou de direitos
              do IAS e/ou de terceiros, o Titular da Conta concorda em indenizar, defender e isentar
              o IAS, seus conselheiros, diretores, representantes, colaboradores e cessionários, por
              todas e quaisquer perdas e danos (inclusive honorários advocatícios) diretos ou
              indiretos a que der causa, decorrentes de demandas promovidas pelo IAS, por outros
              Titulares de Conta e/ou terceiros, relacionadas ao uso da Plataforma ou a estes Termos
              de Uso.
            </Typography>
            <Typography className={classes.text}>
              <strong>7.7. Tolerância. </strong>
            </Typography>
            <Typography className={classes.text}>
              A omissão ou tolerância ao eventual descumprimento de quaisquer das condições deste
              documento não constituirá novação ou renúncia das obrigações aqui estipuladas e
              tampouco impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo pelo IAS. O
              presente documento constitui o acordo integral entre o IAS e o Titular da Conta.
            </Typography>
            <Typography className={classes.text}>
              <strong>8. MANUTENÇÃO E SUPORTE DA PLATAFORMA</strong>
            </Typography>
            <Typography className={classes.text}>
              <strong>8.1. Indisponibilidade da Plataforma. </strong>
            </Typography>
            <Typography className={classes.text}>
              O IAS não garante que a Plataforma funcionará em qualquer sistema, equipamento ou
              dispositivo. Tendo em vista as características inerentes ao ambiente da internet, o
              IAS desde já esclarece que a Plataforma poderá estar sujeita a falhas de acesso ou
              navegação decorrentes de circunstâncias alheias à sua vontade e controle, tais como
              transmissões de computador incompletas ou que falhem, bem como falhas técnicas de
              qualquer natureza, incluindo, sem limitação, mau funcionamento eletrônico e/ou físico
              de qualquer rede, hardware ou software do Titular da Conta, quedas de energia e
              atrasos, interrupções ou suspensões de conexão inerentes ao acesso à Internet e à rede
              de comunicações eletrônicas. O IAS não se responsabiliza pela qualidade ou
              indisponibilidade da rede de acesso à Internet do Titular da Conta (nesta última
              hipótese, incluindo os casos de paralisações emergenciais ou programadas para
              manutenção, atualização e ajustes de configuração da aplicação), assim como qualquer
              informação incorreta ou incompleta fornecida pelo Titular da Conta e pela Plataforma,
              bem como qualquer falha humana, técnica ou de qualquer outro tipo no processamento das
              informações.
            </Typography>
            <Typography className={classes.text}>
              <strong>8.2. Contato. </strong>
            </Typography>
            <Typography className={classes.text}>
              Caso esteja enfrentando qualquer problema técnico, por favor, o Titular da Conta
              deverá entrar em contato com a equipe de suporte do IAS, por meio do envio de mensagem
              para o endereço de suporte (
              <Link target="_blank" rel="noreferrer" href="atendimento.senna@ias.org.br">
                atendimento.senna@ias.org.br
              </Link>
              ).
            </Typography>
            <br />
            <br />
            <br />
            <Typography className={classes.text}> C. DISPOSIÇÕES GERAIS</Typography>
            <br />
            <br />
            <br />
            <Typography className={classes.text}>
              <strong>1. REVISÃO E ALTERAÇÕES A ESTE DOCUMENTO</strong>
            </Typography>
            <Typography className={classes.text}>
              <strong>1.1. Alterações. </strong>
            </Typography>
            <Typography className={classes.text}>
              O IAS poderá alterar os presentes Termos de Uso ao longo do tempo, sempre de acordo
              com a legislação vigente. Toda alteração será válida, eficaz e vinculante a partir da
              publicação e divulgação da nova versão dos Termos de Uso na Plataforma. Em compromisso
              com os nossos valores de transparência, em caso de atualização relevante, o IAS dará
              publicidade a tais modificações de forma destacada. É recomendado que você consulte
              regularmente este documento para estar sempre atualizado com relação aos seus termos.
            </Typography>
            <Typography className={classes.text}>
              <strong>1.2. Consentimento. </strong>
            </Typography>
            <Typography className={classes.text}>
              Ao continuar utilizando a Plataforma a partir das novas condições vigentes, o Titular
              da Conta concorda com todas as modificações realizadas. Em caso de discordância, O
              Titular da Conta poderá rejeitá-las, sob pena de não ter mais acesso à Plataforma, ou
              nos enviar uma mensagem por meio dos nossos canais de comunicação indicados abaixo
              para uma tentativa de solução do problema.
            </Typography>
            <Typography className={classes.text}>
              <strong>2. COMO ENTRAR EM CONTATO COM O IAS SOBRE OS TERMOS DESTE DOCUMENTO</strong>
            </Typography>
            <Typography className={classes.text}>
              <strong>2.1. </strong>
            </Typography>
            <Typography className={classes.text}>
              Em caso de dúvidas, sugestões ou comentários sobre o presente Termo, por favor, entre
              em contato conosco pelo e-mail atendimento.senna@ias.org.br, ou escreva para: Rua Dr.
              Fernandes Coelho, nº 85, 15º/Typographyarte andar – Pinheiros São Paulo, SP CEP:
              05423-040 A/C Soluções de Tecnologia da Informação
            </Typography>
            <Typography className={classes.text}>
              <strong>2.2. </strong>
            </Typography>
            <Typography className={classes.text}>
              No referido endereço, atenderemos somente consultas relacionadas aos termos e
              condições deste documento, não englobando eventuais dúvidas e problemas operacionais
              de utilização da Plataforma.
            </Typography>
            <Typography className={classes.text}>
              <strong>3. LEI APLICÁVEL E JURISDIÇÃO APLICÁVEL</strong>
            </Typography>
            <Typography className={classes.text}>
              <strong>3.1. </strong>
            </Typography>
            <Typography className={classes.text}>
              A presente Política é regida pela legislação da República Federativa do Brasil. O seu
              texto deverá ser interpretado no idioma português. Em caso de qualquer litígio ou
              controvérsia envolvendo o presente documento, os interessados comprometem-se a buscar,
              de boa-fé, por todos os meios de diálogo e modos de composição de interesses ao seu
              alcance, uma solução concertada para a questão. Não obtida a solução para o conflito,
              fica eleito o foro da cidade de São Paulo.
            </Typography>
          </Grid>
        </Grid>
      </>
    </Modal>
  )
}

TermsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
}

export default TermsDialog
