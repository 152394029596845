import PropTypes from 'prop-types'
import React, { createContext, useState } from 'react'

export const ImportTrackingContext = createContext()

export const ImportTrackingProvider = ({ children }) => {
  const [city, setCity] = useState({})
  const [school, setSchool] = useState({})
  const [frequencyId, setFrequencyId] = useState({})
  const [frequencyItemId, setFrequencyItemId] = useState({})
  const [program, setProgram] = useState({})
  const [year, setYear] = useState({})
  const [url, setUrl] = useState({})
  const [level, setLevel] = useState({})
  const [refreshData, setRefreshData] = useState(false)

  return (
    <ImportTrackingContext.Provider
      value={{
        city,
        setCity,
        school,
        setSchool,
        frequencyId,
        setFrequencyId,
        frequencyItemId,
        setFrequencyItemId,
        program,
        setProgram,
        year,
        setYear,
        url,
        setUrl,
        level,
        setLevel,
        refreshData,
        setRefreshData,
      }}
    >
      {children}
    </ImportTrackingContext.Provider>
  )
}

export default ImportTrackingProvider

ImportTrackingProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
